import Section from "../../../section/Section";

export default function AskNimVideoSection() {
	return (
		<Section className="asknim-video-section pt-10 pt-md-15 pb-15 pb-md-20">
			<div className="d-flex flex-column text-center align-items-center">
				<h2 className="pb-8 pb-md-12" style={{ maxWidth: 498 }}>
					Why Ask.Nim is a Manager’s Best Friend
				</h2>

				<div className="d-flex justify-content-center align-items-center" style={{ maxWidth: 900, width: "100%" }}>
					<iframe
						frameBorder="0"
						allowFullScreen="1"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						title="YouTube video player"
						width="100%"
						height="auto"
						style={{ aspectRatio: "16 / 9", width: "100% !important", height: "auto" }}
						src={`https://www.youtube.com/embed/JX5jauXlpIs?autoplay=0&amp;mute=0&amp;controls=1&amp;origin=https%3A%2F%2Fapp.staynimble.co.uk&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=1&amp;enablejsapi=1&amp;widgetid=1`}
					/>
				</div>
			</div>
		</Section>
	);
}
